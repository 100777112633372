import { InAppReview } from "@capacitor-community/in-app-review";
import { isDefined } from "@clipboard-health/util-ts";
import { isPlatform } from "@ionic/core";
import { useWorkerPayroll } from "@src/appV2/Accounts/Payroll/api/useWorkerPayroll";
import { AgentStage } from "@src/appV2/Agents";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { APP_V2_USER_EVENTS, logError, logEvent } from "@src/appV2/lib/analytics";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { addDays, isAfter, subDays } from "date-fns";
import { useCallback } from "react";

import { TriggerStrategyNames } from "./types";

export const ALREADY_CHECKED_ELIGIBLE_SHIFTS_LOCAL_STORAGE_KEY =
  "in-app-rating-prompt-already-checked-eligible-shifts";
export const RATING_PROMPT_DATE_LOCAL_STORAGE_KEY = "in-app-rating-prompt-shown-date";

type TriggerStrategy =
  | {
      name: typeof TriggerStrategyNames.on_route_open;
      route: string;
    }
  | {
      name: typeof TriggerStrategyNames.on_component_render;

      // Make sure to update feature flag's triggerStrategies value when changing this
      component: "WorkedShiftFeedbackModal";
    };

export function useShowInAppRatingPrompt() {
  const featureFlag = useCbhFlag(CbhFeatureFlag.IN_APP_RATING_PROMPT, {
    defaultValue: {
      isEnabled: false,
      promptCooldownPeriodInDays: 30,
      minimumEligibleShiftDurationInHours: 4,
      paidShiftCutoffDate: new Date().toISOString(),
      triggerStrategies: {},
    },
  });

  const worker = useDefinedWorker();

  const { refetch: fetchWorkerPayroll } = useWorkerPayroll(
    {
      // longest time period to check that the backend supports is 31 days, anything longer than that will be invalid input
      startDate: subDays(new Date(), 31).toISOString(),
      endDate: new Date().toISOString(),
    },
    {
      // disabling to only fetch it if the criteria matches
      enabled: false,
      useErrorBoundary: false,
    }
  );

  const showPrompt = useCallback((params?: { delayInMs?: number }) => {
    if (params?.delayInMs) {
      setTimeout(() => {
        void InAppReview.requestReview().catch((error: Error) => {
          logError(APP_V2_USER_EVENTS.IN_APP_RATING_PROMPT_ERROR, { error });
        });
      }, params.delayInMs);
    } else {
      void InAppReview.requestReview().catch((error: Error) => {
        logError(APP_V2_USER_EVENTS.IN_APP_RATING_PROMPT_ERROR, { error });
      });
    }

    localStorage.setItem(RATING_PROMPT_DATE_LOCAL_STORAGE_KEY, new Date().toISOString());
    localStorage.setItem(ALREADY_CHECKED_ELIGIBLE_SHIFTS_LOCAL_STORAGE_KEY, JSON.stringify(true));
    logEvent(APP_V2_USER_EVENTS.IN_APP_RATING_PROMPT_TRIGGERED);
  }, []);

  /**
   * `mobileWeb` refers to a website accessed via a mobile browser
   * For reference, see: https://ionicframework.com/docs/react/platform#platforms
   *
   *
   * Using `isPlatform("ios") || isPlatform("android")`
   * alone might not be sufficient, as opening a mobile browser on iOS/Android still triggers
   * `isPlatform("ios")`/`isPlatform("android")` as true. See discussion:
   * https://github.com/ClipboardHealth/cbh-mobile-app/pull/4760#discussion_r1842248286
   */
  const isOnCorrectPlatform =
    (isPlatform("ios") || isPlatform("android")) && !isPlatform("mobileweb");

  const fetchPayrollAndShowPromptIfEligible = useCallback(async () => {
    logEvent(APP_V2_USER_EVENTS.IN_APP_RATING_PROMPT_FETCH_PAYROLL);
    const alreadyHasAnEligiblePaidShift = Boolean(
      JSON.parse(localStorage.getItem(ALREADY_CHECKED_ELIGIBLE_SHIFTS_LOCAL_STORAGE_KEY) ?? "false")
    );

    if (alreadyHasAnEligiblePaidShift) {
      showPrompt();
      return;
    }

    const workerPayroll = await fetchWorkerPayroll();
    const hasAnEligiblePaidShift =
      workerPayroll.isSuccess &&
      workerPayroll.data.shifts.some(
        (shift) =>
          shift.paymentStatus.status === "Full Paid" &&
          shift.isInstantPay &&
          shift.time >= featureFlag.minimumEligibleShiftDurationInHours &&
          isAfter(new Date(shift.end), new Date(featureFlag.paidShiftCutoffDate))
      );
    if (hasAnEligiblePaidShift) {
      showPrompt();
    }
  }, [
    featureFlag.minimumEligibleShiftDurationInHours,
    featureFlag.paidShiftCutoffDate,
    fetchWorkerPayroll,
    showPrompt,
  ]);

  const requestInAppRatingIfTriggerEnabled = useCallback(
    async (params: { triggerStrategy: TriggerStrategy }) => {
      const { triggerStrategy: strategy } = params;
      try {
        if (!featureFlag.isEnabled) {
          logEvent(APP_V2_USER_EVENTS.IN_APP_RATING_PROMPT_NOT_TRIGGERED, {
            featureFlag,
            reason: "Feature flag is not enabled",
          });
          return;
        }

        if (!isOnCorrectPlatform) {
          logEvent(APP_V2_USER_EVENTS.IN_APP_RATING_PROMPT_NOT_TRIGGERED, {
            featureFlag,
            reason: "Not on correct platform",
          });
          return;
        }

        // we only want to show the prompt if the user is enrolled
        if (worker.stage !== AgentStage.ENROLLED) {
          logEvent(APP_V2_USER_EVENTS.IN_APP_RATING_PROMPT_NOT_TRIGGERED, {
            featureFlag,
            reason: "Agent is not enrolled",
          });
          return;
        }

        const promptLastShownAt = localStorage.getItem(RATING_PROMPT_DATE_LOCAL_STORAGE_KEY);
        const hasBeenShownPromptTooRecently =
          isDefined(promptLastShownAt) &&
          isAfter(
            addDays(new Date(promptLastShownAt), featureFlag.promptCooldownPeriodInDays),
            new Date()
          );
        if (hasBeenShownPromptTooRecently) {
          logEvent(APP_V2_USER_EVENTS.IN_APP_RATING_PROMPT_NOT_TRIGGERED, {
            featureFlag,
            reason: "Prompt has been shown too recently",
          });
          return;
        }

        if (
          strategy.name === "on_route_open" &&
          "on_route_open" in featureFlag.triggerStrategies &&
          featureFlag.triggerStrategies.on_route_open?.routes.includes(strategy.route)
        ) {
          logEvent(APP_V2_USER_EVENTS.IN_APP_RATING_PROMPT_REQUESTED, {
            triggerStrategy: strategy,
          });
          await fetchPayrollAndShowPromptIfEligible();
        }

        if (
          strategy.name === TriggerStrategyNames.on_component_render &&
          TriggerStrategyNames.on_component_render in featureFlag.triggerStrategies &&
          featureFlag.triggerStrategies.on_component_render?.components.includes(strategy.component)
        ) {
          logEvent(APP_V2_USER_EVENTS.IN_APP_RATING_PROMPT_REQUESTED, {
            triggerStrategy: strategy,
          });
          showPrompt({
            delayInMs: featureFlag.triggerStrategies.on_component_render?.triggerDelayInMs ?? 1000,
          });
        }
      } catch (error) {
        logError(APP_V2_USER_EVENTS.IN_APP_RATING_PROMPT_ERROR, { error });
      }
    },
    [
      featureFlag,
      isOnCorrectPlatform,
      worker.stage,
      fetchPayrollAndShowPromptIfEligible,
      showPrompt,
    ]
  );

  return {
    requestInAppRatingIfTriggerEnabled,
  };
}
