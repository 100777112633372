import { AgentStage } from "@src/appV2/Agents";
import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

const latenessConfigSchema = z.array(
  z.object({
    points: z.number(),
    minutesLateRange: z.object({
      startFrom: z.number(),
      stopAt: z.number().optional(),
    }),
  })
);

export const attendanceScorePolicySchema = z
  .object({
    workShift: z
      .object({
        points: z.number(),
      })
      .optional(),
    qualityBonusConfig: z
      .object({
        points: z.number(),
      })
      .optional(),
    suspension: z
      .array(
        z.object({
          score: z.tuple([z.number().nullable(), z.number().nullable()]),
          duration: z.number(),
        })
      )
      .optional(),
    cancelShift: z
      .array(
        z.object({
          points: z.number(),
          leadTime: z.array(z.number().nullable()),
        })
      )
      .optional(),
    latenessConfig: latenessConfigSchema.optional(),
    shiftClockInConfig: z
      .object({
        points: z.number(),
      })
      .optional(),
    deactivation: z
      .object({
        window: z.number(),
        duration: z.number(),
        suspension: z.number(),
      })
      .optional(),
    noCallNoShow: z
      .object({
        points: z.number(),
      })
      .optional(),
    finalRestriction: z
      .object({
        window: z.number(),
        duration: z.number(),
        restriction: z.number(),
      })
      .optional(),
    restriction: z
      .array(
        z.object({
          score: z.tuple([z.number().nullable(), z.number().nullable()]),
          duration: z.number(),
        })
      )
      .optional(),
  })
  .optional();

export const attendanceScoreProfileResponseSchema = z.object({
  score: z.number(),
  clipboardScore: z.number().optional(),
  clipboardScoreRank: z
    .object({
      rank: z.number(),
      outOf: z.number(),
    })
    .optional(),
  reliabilityScore: z.number().optional(),
  reliabilityScoreRank: z
    .object({
      rank: z.number().optional(),
      outOf: z.number().optional(),
    })
    .optional(),
  suspensionCount: z.number().optional(),
  latenessPointsDeductedInLast30Days: z.number().optional(),
  latenessBooktimeThreshold: z.number().optional(),
  accountStatus: z.object({
    status: z
      .enum([
        AgentStage.ONBOARDING,
        AgentStage.ENROLLED,
        AgentStage.PROBATION,
        AgentStage.DEACTIVATED,
        AgentStage.RESTRICTED,
      ])
      .nullish(),
    reEnrollmentDate: z.string().optional(),
  }),
  policy: attendanceScorePolicySchema,
  restrictionCount: z.number().optional(),
});

export type AttendanceScorePolicy = z.infer<typeof attendanceScorePolicySchema>;
export type AttendanceScoreProfileResponse = z.infer<typeof attendanceScoreProfileResponseSchema>;
export type FinalRestriction = NonNullable<
  AttendanceScoreProfileResponse["policy"]
>["finalRestriction"];

export type LatenessConfigResponse = z.infer<typeof latenessConfigSchema>;

export function getAttendanceScoreProfilePath(userId: string): string {
  return `/workers/${userId}/profile`;
}

export function useGetAttendanceScoreProfile(
  userId: string | undefined,
  options: UseGetQueryOptions<AttendanceScoreProfileResponse> = {}
): UseQueryResult<AttendanceScoreProfileResponse> {
  const url = `${
    environmentConfig.REACT_APP_ATTENDANCE_SCORE_SERVICE_URL
  }${getAttendanceScoreProfilePath(userId ?? "")}`;
  return useGetQuery({
    url,
    responseSchema: attendanceScoreProfileResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_ATTENDANCE_SCORE_PROFILE,
      userErrorMessage: "Something went wrong while loading your attendance score.",
    },
    enabled: Boolean(userId),
    ...options,
  });
}
